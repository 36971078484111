<template>
  <div class="elv-setting-project-form">
    <el-form ref="projectFormRef" :model="projectForm" :rules="rules" label-position="top">
      <el-form-item v-if="showFormItemOfList('name')" :label="t('report.projectName')" prop="name">
        <el-input v-model="projectForm.name" minlength="1" maxlength="64" :placeholder="t('report.projectName')" />
      </el-form-item>
      <el-form-item v-if="showFormItemOfList('companyName')" :label="t('report.companyFullName')" prop="companyName">
        <el-input
          v-model="projectForm.companyName"
          minlength="1"
          maxlength="64"
          :placeholder="t('placeholder.legalNameOfCompany')"
        />
      </el-form-item>
      <el-form-item
        v-if="showFormItemOfList('region') && showFormItemOfList('employees')"
        class="elv-setting-project-form-multiple-item"
      >
        <el-form-item v-if="showFormItemOfList('region')" :label="t('report.countryRegion')" prop="region">
          <el-select
            v-model="projectForm.region"
            filterable
            :disabled="props.currentEntityData?.region === 'Singapore' && enableGst && props.model === 'edit'"
            popper-class="elv-setting-project-form-popper"
            style="width: 278px"
            :placeholder="t('common.pleaseChoose')"
            :suffix-icon="SelectSuffixIcon"
          >
            <el-option
              v-for="(item, index) in countryOptions"
              :key="index"
              :label="locale === 'en' ? item.name_en : item.name_zh"
              :value="item.name_en"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="showFormItemOfList('employees')" :label="t('report.employees')" prop="employees">
          <ElvSelect
            v-model="projectForm.employees"
            :options="employeesOptions"
            width="278px"
            :placeholder="t('common.pleaseChoose')"
          />
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="showFormItemOfList('website')" :label="t('report.website')" prop="website">
        <el-input
          v-model="projectForm.website"
          minlength="1"
          maxlength="64"
          :placeholder="t('placeholder.beginWithWebsites')"
        />
      </el-form-item>
      <el-form-item
        v-if="showFormItemOfList('firstName') && showFormItemOfList('lastName')"
        class="elv-setting-project-form-multiple-item"
      >
        <el-form-item v-if="showFormItemOfList('firstName')" :label="t('report.yourFirstName')" prop="firstName">
          <el-input
            v-model="projectForm.firstName"
            minlength="1"
            maxlength="64"
            :placeholder="t('placeholder.firstName')"
          />
        </el-form-item>
        <el-form-item v-if="showFormItemOfList('lastName')" :label="t('report.yourLastName')" prop="lastName">
          <el-input
            v-model="projectForm.lastName"
            minlength="1"
            maxlength="64"
            :placeholder="t('placeholder.lastName')"
          />
        </el-form-item>
      </el-form-item>
      <el-form-item
        v-if="showFormItemOfList('jobTitle') && showFormItemOfList('email')"
        class="elv-setting-project-form-multiple-item"
      >
        <el-form-item v-if="showFormItemOfList('jobTitle')" :label="t('report.yourJobTitle')" prop="jobTitle">
          <el-input
            v-model="projectForm.jobTitle"
            minlength="1"
            maxlength="64"
            :placeholder="t('placeholder.jobTitle')"
          />
        </el-form-item>
        <el-form-item v-if="showFormItemOfList('email')" :label="t('report.yourWorkEmail')" prop="email">
          <el-input
            v-model="projectForm.email"
            minlength="1"
            maxlength="64"
            :placeholder="t('placeholder.workEmailTip')"
          />
        </el-form-item>
      </el-form-item>
      <el-form-item v-if="props.isPublicFree" :label="t('title.referral')">
        <el-input
          v-model="projectForm.ref"
          :placeholder="t('placeholder.referralTip')"
          :disabled="!!route?.query?.ref"
        />
      </el-form-item>
    </el-form>
    <div v-if="props.model === 'add'" class="elv-setting-project-form-term-service">
      <div class="elv-setting-project-form-term-service-agree">
        <el-checkbox v-model="agreeTerms" size="small" />
        <i18n-t
          keypath="message.agreeServicesAgreement"
          tag="p"
          class="elv-setting-project-form-term-service-agree-tip"
          scope="global"
        >
          <template #agreement>
            <span @click="onOpenServicesAgreement">{{ t('common.mainServicesAgreement') }}</span>
          </template>
        </i18n-t>
      </div>
      <i18n-t
        keypath="message.createProjectTermsOfService"
        tag="p"
        class="elv-setting-project-form-term-service-treaty"
        scope="global"
      >
        <template #policy>
          <span @click="onOpenPrivacyPolicy">{{ t('common.serviceAgreement') }}</span>
        </template>
      </i18n-t>
    </div>
    <elv-button
      :width="props.model === 'edit' ? 156 : 159"
      height="44"
      round
      type="primary"
      :loading="submitLoading"
      :disabled="disabledButton"
      class="elv-apply-trial-submit"
      @click="onSubmitSave"
      >{{ props.model === 'add' && !props.isPublicFree ? t('button.subscribe') : t('button.submit') }}</elv-button
    >
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import countryList from '@/config/country'
import { isEmpty, sortBy } from 'lodash-es'
import ElvSelect from '../Base/ElvSelect.vue'
import SelectSuffixIcon from './SelectSuffixIcon.vue'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import type { FormInstance, FormRules } from 'element-plus'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const props = defineProps({
  model: {
    type: String,
    default: 'add' // add | edit
  },
  currentEntityData: {
    type: Object,
    default: () => {}
  },
  planType: {
    type: String,
    default: 'BASIC' // FREE | BASIC | STANDARD
  },
  isPublicFree: {
    type: Boolean,
    default: false
  },
  showFormList: {
    type: Array<string>,
    default: () => []
  }
})

const emit = defineEmits(['onCheckEntityDialog', 'onCreateProjectSuccess'])

const route = useRoute()
const { t, locale } = useI18n()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()
const userGlobalStore = useUserGlobalStore()

const enableGst = ref(false)
const agreeTerms = ref(false)
const submitLoading = ref(false)
const projectFormRef = ref<FormInstance>()

const freeEmails = [
  'mail.com',
  'yandex.com',
  'gmail.com',
  'outlook.com',
  'live.com',
  'yahoo.com',
  'qq.com',
  '163.com',
  '126.com',
  'hotmail.com',
  'icloud.com',
  'aol.com',
  'zoho.com',
  'me.com',
  'foxmail.com'
]

const projectForm = reactive({
  name: '',
  firstName: '',
  lastName: '',
  companyName: '',
  employees: '',
  website: '',
  jobTitle: '',
  email: '',
  region: '',
  plan: props.planType,
  ref: ''
})

const rules = reactive<FormRules>({
  website: [
    {
      trigger: 'blur',
      validator: (rule: any, value: string, callback) => {
        if (!value) return callback()
        const websiteRules = /^(http|https):\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*.*$/
        if (websiteRules.test(value)) {
          return callback()
        }
        return callback(new Error(`${t('message.enterWebsiteUrlWarningInfo')}`))
      }
    }
  ],
  email: [
    {
      trigger: 'blur',
      validator: (rule: any, value: string, callback) => {
        if (!value) return callback()
        // eslint-disable-next-line no-useless-escape
        if (!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/.test(value)) {
          return callback(new Error(`${t('message.loginEmailPlaceholder')}`))
        }
        const domainMatch = value.match(/@([a-zA-Z0-9.-]+)$/)
        if (domainMatch && domainMatch.length > 1 && route?.query?.limitation !== '0') {
          const domain = domainMatch[1]
          // 检查域名是否在不允许的域名列表中
          if (freeEmails.includes(domain)) {
            return callback(new Error(`${t('placeholder.useWorkEmailApply')}`))
          }
        }
        return callback()
      }
    }
  ]
})

const employeesOptions = computed(() => {
  const suffixType = t('report.employees').toLocaleLowerCase()
  return [
    { value: 'LEVEL1', label: `1-21 ${suffixType}` },
    { value: 'LEVEL2', label: `21-50 ${suffixType}` },
    { value: 'LEVEL3', label: `51-100 ${suffixType}` },
    { value: 'LEVEL4', label: `101-500 ${suffixType}` },
    { value: 'LEVEL5', label: `501-2,000 ${suffixType}` },
    { value: 'LEVEL6', label: `2,000+ ${suffixType}` }
  ]
})

const isRightWebsite = computed(() => {
  if (!projectForm.website) return true
  const websiteRules = /^(http|https):\/\/[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*.*$/
  return websiteRules.test(projectForm.website)
})

const isRightEmail = computed(() => {
  if (!projectForm.email) return true
  // eslint-disable-next-line no-useless-escape
  const isEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/.test(projectForm.email)
  if (!isEmail) return false
  const domainMatch = projectForm.email.match(/@([a-zA-Z0-9.-]+)$/)
  if (route?.query?.limitation === '0') return true
  if (domainMatch && domainMatch.length > 1) {
    const domain = domainMatch[1]
    // 检查域名是否在不允许的域名列表中
    return !freeEmails.includes(domain)
  }
  return false
})

const countryOptions = computed(() => {
  if (locale.value === 'zh') return sortBy(countryList, [(o) => o.name_zh])
  return sortBy(countryList, [(o) => o.name_en])
})

const disabledButton = computed(() => {
  if (!Array.isArray(props.showFormList) || !props.showFormList.length) {
    return (
      Object.values(projectForm).some((item) => !item || !item.length) ||
      !agreeTerms.value ||
      !isRightWebsite.value ||
      !isRightEmail.value
    )
  }
  const data: any = { ...projectForm }
  return (
    (props.showFormList.length && !props.showFormList.every((item) => data[item] && data[item].length)) ||
    !agreeTerms.value ||
    (props.showFormList.includes('website') && !isRightWebsite.value) ||
    (props.showFormList.includes('email') && !isRightEmail.value)
  )
})

const showFormItemOfList = computed(() => (itemName: string) => {
  return !props.showFormList.length || (props.showFormList.length && props.showFormList.includes(itemName))
})

/**
 * @description: 创建Project
 */
const onCreateProject = async () => {
  try {
    const params: any = { ...projectForm }
    Object.keys(params).forEach((key) => {
      if (!params[key] || params[key] === undefined) {
        delete params[key]
      }
    })
    submitLoading.value = true

    let data: any
    if (!props.isPublicFree) {
      delete params?.ref
      const response = await ProjectApi.createNewProject(params)
      data = response.data
    } else {
      params.limitation = route?.query?.limitation === '0' ? 0 : 1
      await ProjectApi.projectCreateByOpenFree(params)
      emit('onCreateProjectSuccess')
    }
    ElMessage.success(t('message.createProjectSuccess'))
    if (!props.isPublicFree) {
      await Promise.all([
        globalStore.fetchProjectList(),
        userGlobalStore.userInit(),
        projectStore.setProjectDetail(data)
      ])
      emit('onCreateProjectSuccess')
      menuGlobalStore.menuListInit()
    } else {
      emit('onCheckEntityDialog')
    }
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    submitLoading.value = false
  }
}

/**
 * @description 编辑Project
 */
const onEditProject = async () => {
  const projectId = (route?.params?.projectId as string) || projectStore.projectDetail?.projectId || ''
  try {
    submitLoading.value = true
    const params: any = { ...projectForm }
    delete params?.plan
    if (!props.isPublicFree) {
      delete params?.ref
    }
    await ProjectApi.editProjectDetail(projectId, params)
    ElMessage.success(t('message.saveSuccess'))
    projectStore.fetchProjectDetail(projectId)
    await globalStore.fetchProjectList()
    menuGlobalStore.menuListInit()
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    submitLoading.value = false
  }
}

/**
 * @description: 提交保存
 */
const onSubmitSave = async () => {
  return projectFormRef.value?.validate(async (valid: boolean) => {
    if (valid) {
      submitLoading.value = true
      if (props.model === 'add') {
        await onCreateProject()
      } else {
        await onEditProject()
      }
    }
  })
}

/**
 * 打开服务协议
 */
const onOpenServicesAgreement = () => {
  window.open('https://www.elven.com/service-agreement ', '_blank')
}

/**
 * 打开隐私政策
 */
const onOpenPrivacyPolicy = () => {
  window.open('https://www.elven.com/privacy', '_blank')
}

watch(
  () => props.currentEntityData,
  async (val) => {
    if (!isEmpty(props.currentEntityData)) {
      projectFormRef.value?.resetFields()
      projectForm.name = val.name
      projectForm.companyName = val?.companyName
      projectForm.region = val?.region
      projectForm.employees = val?.employees
      projectForm.website = val?.website
      projectForm.firstName = val?.firstName
      projectForm.lastName = val?.lastName
      projectForm.jobTitle = val?.jobTitle
      projectForm.email = val?.email
      enableGst.value = val?.enableGst || false
      agreeTerms.value = true
    } else {
      projectFormRef.value?.resetFields()
      if (props.isPublicFree && route?.query?.ref) {
        projectForm.ref = route?.query?.ref as string
      }
    }
  },
  {
    immediate: true
  }
)

defineExpose({ onSubmit: onSubmitSave, disabledButton })
</script>

<style lang="scss">
.elv-setting-project-form {
  margin-top: 32px;
  padding-bottom: 20px;

  .el-input {
    width: 572px;
    height: 44px;
    border-radius: 4px;

    &.el-date-editor--date {
      .el-input__prefix {
        display: none;
      }
    }

    &.is-disabled {
      background: #f9fafb;

      .el-input__wrapper {
        background: #f9fafb;
        border: 1px solid #dde1e6;
        box-shadow: none;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));

        .el-input__suffix {
          svg,
          i {
            width: 20px;
            height: 20px;
            fill: #d0d4d9;
          }
        }
      }

      .el-input__inner {
        color: #0e0f11;
        -webkit-text-fill-color: #0e0f11;
      }
    }
  }

  .el-textarea:not(.is-disabled) .el-textarea__inner {
    &:hover {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    &:focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .elv-setting-project-form-checkbox {
    .el-checkbox__input,
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
    }

    .el-checkbox__inner::after {
      left: 4.5px !important;
      top: 1.5px !important;
    }

    .el-checkbox__label {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      span {
        color: #eb3a30;
        font-size: 13px;
        margin-left: 8px;
      }
    }
  }

  .el-textarea__inner {
    padding: 8px 16px;
    width: 572px;
    min-height: 100px;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #0e0f11;

    &:hover,
    &:focus {
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    }
  }

  .el-input__inner {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    color: #0e0f11;
  }

  .el-input__wrapper {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: 4px;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    padding: 1px 12px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    .el-input__suffix {
      svg,
      i {
        width: 20px;
        height: 20px;
        fill: #838d95;
      }
    }
  }

  .el-input:not(.is-disabled) .el-input__wrapper {
    &:hover {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    &.is_focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-form-item {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    &.is-error {
      .el-input__wrapper {
        background: #faeee6;
        border: 1px solid #7e4a15;
        box-shadow: none;
      }

      .el-input__inner {
        color: #7e4a15;
      }

      .el-form-item__error {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #7e4a15;
      }
    }

    .el-form-item__label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      pointer-events: none;
    }

    &.elv-setting-project-form-item-flex .el-form-item__label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      span {
        position: absolute;
        right: 0;
        color: #eb3a30;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: none;

        svg {
          margin-right: 4px;
          margin-top: 1px;
        }
      }
    }

    .el-select.elv-setting-project-form-item__currency .el-select__wrapper {
      flex-direction: row-reverse;

      .el-select__suffix {
        display: none;
      }

      .el-select__prefix {
        svg {
          width: 14px;
          height: 14px;
          fill: #a8abb2;
          transition: 0.3s;
        }
      }
    }
  }

  .elv-setting-project-form-multiple-item {
    .el-form-item__content {
      gap: 16px;

      .el-form-item {
        margin-bottom: 0px;
      }

      .el-input {
        width: 278px;
      }
    }
  }

  .el-select {
    .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    span {
      max-width: 220px !important;
    }
  }

  .el-input__wrapper.is-focus {
    border: 1px solid #7596eb !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
  }

  .elv-apply-trial-submit {
    margin-top: 16px;
    width: 114px;
    height: 44px;

    &.is-center {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.elv-setting-project-date-shell-popper.el-popper {
  display: none;
}

.elv-setting-project-form-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        width: 32px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}

.elv-setting-project-form-term-service {
  width: 572px;
  height: 106px;
  background-color: #f7f9ff;
  border: 1px solid #dde1e6;
  border-radius: 4px;
  padding: 12px;
  color: #4e515a;
  font-size: 13px;
  margin-top: 16px;
  box-sizing: border-box;

  &-agree {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;

    .el-checkbox--small {
      height: 18px;

      .el-checkbox__inner {
        width: 16px;
        height: 16px;

        &::after {
          top: 1.8px;
          width: 3px;
          left: 4.5px;
        }
      }
    }
  }

  &-agree-tip {
    margin-left: 7px;

    span {
      color: #1753eb;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &-treaty {
    line-height: 17px;
    padding-left: 25px;

    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
